console.log('lemon loading')
var lemonVariables = {}

window.onClickLightDark = function () {
  console.log('onClickLightDark')
  document.documentElement.classList.toggle('dark')
}

window.selectOnChange = function (event) {
  console.log(event)
  console.log(event.target)
  console.log(event.target.value)
  console.log(event.target.attributes['data-var'].value)
  console.log(JSON.parse(event.target.value))
  const variableName = event.target.attributes['data-var'].value
  const value = JSON.parse(event.target.value)
  lemonVariables[variableName] = value
}

window.onload = function () {
  // init all select elements
  const selectElements = document.querySelectorAll('select')
  selectElements.forEach(function (selectElement) {
    const variableName = selectElement.attributes['data-var'].value
    const value = JSON.parse(selectElement.value)
    lemonVariables[variableName] = value
    console.log(variableName)
    console.log(value)
  })
}

window.buttonLink = function (link = '') {
  const result = replaceVars(link)
  window.open(result, '_blank')
}

function replaceVars (inputString) {
  // Regular expression pattern to match $var(x)
  const regex = /\$var\(([^)]+)\)/g

  // Replace occurrences of $var(x) with corresponding values from the object
  const result = inputString.replace(regex, function (match, key) {
    // Access the value from the object using dot notation
    const value = getObjectValue(lemonVariables, key)
    return value !== undefined ? value : ''
  })

  return result
}

// Helper function to access nested values from the object using dot notation
function getObjectValue (object, key) {
  const keys = key.split('.')
  let value = object

  for (let i = 0; i < keys.length; i++) {
    if (!value || typeof value !== 'object') {
      return undefined
    }
    value = value[keys[i]]
  }

  return value
}
